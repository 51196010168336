import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

import { SkeletonError } from "~/components/core/StatisticCard/StatisticCardSkeleton";
import { useAuthenticatedFetch } from "~/utils/http";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";
import { Ratings } from "./Ratings";

const DashboardTutorRatingsOfStudents = createDashboardLazyWidget({
  displayName: "DashboardTutorRatingsOfStudents",
  contentHeight: 300,
  caption: "Tutor ratings of students",
  className: "flex-[45%] md:flex-[33%]  relative",
  captionClassName: "!p-[24px]",
  // testid: struct.adminDash.dashboard.generalUsage.totalSessionsChart,
  useRender: (apiQuery, resetFilters) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/new_stats/session_ratings/",
      { ...apiQuery, isStudentRatings: false },
      true
    );

    // React.useEffect(() => {
    //   trackEvent("load_total_sessions_chart", undefined);
    // }, []);

    return (
      <>
        {error || loading ? (
          <Skeleton
            className="h-[calc(100%-32px)] flex items-center justify-center bg-cream-300 mx-[24px] my-[12px] transform-none rounded-[8px]"
            animation={error ? false : "pulse"}
          >
            {error && <SkeletonError isChart />}
          </Skeleton>
        ) : (
          <div className="flex gap-[48px] my-[12px] mx-[24px]">
            <Ratings lessons={true} data={data} isTutor />
          </div>
        )}
      </>
    );
  }
});

export default DashboardTutorRatingsOfStudents;
