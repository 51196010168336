import RadioChecked from "@material-design-icons/svg/filled/radio_button_checked.svg";
import RadioUnchecked from "@material-design-icons/svg/filled/radio_button_unchecked.svg";
import ArrowIcon from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import classNames from "classnames";
import React from "react";

import { SubjectGroup } from "~/declarations/models/Subject";
import { struct } from "~/utils/struct";

import { Button } from "../Button";

interface IProps {
  subj: SubjectGroup;
  onSelect: () => void;
  selectChildren?: () => void;
  isCategory?: boolean;
  multiSelect?: boolean;
  selectedSubjectIds: number[];
  testid?: React.ButtonTestID;
}

export const SubjectMenuItem: React.FC<IProps> = ({
  subj,
  onSelect,
  selectChildren,
  isCategory,
  multiSelect,
  selectedSubjectIds,
  testid
}) => {
  const selected = selectedSubjectIds.includes(subj.id);

  const wrapperClassname = classNames(
    "relative shrink-0 inline rounded-full border-[2px] border-solid overflow-hidden",
    "border-transparent group-focus:border-chalkboard-700"
  );
  const iconClassName = classNames(
    "w-[24px] h-[24px] relative z-[1]",
    selected && "text-chalkboard-700 fill-current",
    "group-hover:bg-newGrowth-100 group-active:bg-newGrowth-200"
  );

  return (
    <div className="w-full flex items-center justify-between py-[6px] pl-[16px] pr-[4px]">
      <Button
        variant="wrapper"
        action={selectChildren && !multiSelect ? selectChildren : onSelect}
        className="flex items-center justify-between !w-full group"
        data-testid={testid}
      >
        <div className="flex items-center gap-[8px] text-left">
          {isCategory ? (
            <ArrowIcon
              aria-hidden
              className="w-[24px] h-[24px] rotate-90 rounded-[4px] group-hover:bg-gray-100"
            />
          ) : (
            <div className={wrapperClassname}>
              {selected ? (
                <RadioChecked className={iconClassName} />
              ) : (
                <RadioUnchecked className={iconClassName} />
              )}
            </div>
          )}
          {subj.name}
        </div>
        {selectChildren && !multiSelect && (
          <ArrowIcon
            aria-hidden
            className="w-[24px] h-[24px] rotate-[-90deg] rounded-[4px]"
          />
        )}
      </Button>
      {selectChildren && multiSelect && (
        <Button
          variant="wrapper"
          className="rounded-[4px]"
          action={selectChildren}
          aria-label="View sub-subjects"
          data-testid={struct.adminDash.queryFilters.subjects.categoryChildren(
            subj.name
          )}
        >
          <ArrowIcon
            aria-hidden
            className="w-[24px] h-[24px] rotate-[-90deg] rounded-[4px]"
          />
        </Button>
      )}
    </div>
  );
};
