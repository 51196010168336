import { SubjectGroup } from "~/declarations/models/Subject";

export const findParent = (
  subjectID: number,
  subjects: SubjectGroup[],
  parent?: SubjectGroup
): undefined | { subj: SubjectGroup; parent: SubjectGroup | "all" } => {
  for (const subj of subjects) {
    if (subj.id === subjectID) {
      return { parent: parent ? parent : "all", subj };
    }
    if (subj.children) {
      const foundSubj = findParent(subjectID, subj.children, subj);
      if (foundSubj) {
        return foundSubj;
      }
    }
  }
  return undefined;
};

// returns the path to the subject with the given id, ie. [1, 2, 3]
// Algebra # 10082 returns [10001, 10074, 10082], which is Math, Early Math, Algebra
// length could be 1, 2, or 3, or null if something went wrong
export const findSubjectPath = (
  subjects: SubjectGroup[],
  subjectId: number,
  currentPath?: number[]
): number[] | null => {
  let done = false;
  let path: number[] = currentPath ?? [];

  for (let i = 0; i < subjects.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const subject = subjects[i]!;
    if (subject.id === subjectId) {
      path.push(subject.id);
      done = true;
      break;
    } else {
      const foundInChildren = findSubjectPath(
        subject.children ?? [],
        subjectId,
        [...path, subject.id]
      );
      if (foundInChildren) {
        path = foundInChildren;
        done = true;
        break;
      }
    }
  }
  return done ? path : null;
};

// returns array of subj ids of the subject, its children, children of children etc
export const getSubjIds = (subject: SubjectGroup[]): number[] => {
  return (subject || []).reduce((acc, c) => {
    return c.children?.length
      ? acc.concat([c.id], getSubjIds(c.children))
      : acc.concat([c.id]);
  }, [] as number[]);
};

// update selectedSubjectIds from multiselect- remove if selected, add if not
export const handleMultiSelect = (
  selectedSubjectIds: number[],
  subj: SubjectGroup,
  category: SubjectGroup | null,
  allSubjects: SubjectGroup[]
): number[] => {
  let subjectIds: number[] = [];

  const subjIdsToUpdate = getSubjIds([subj]);

  // already selected, remove
  if (selectedSubjectIds.includes(subj.id)) {
    subjectIds = selectedSubjectIds
      .slice()
      .filter(id => !subjIdsToUpdate.includes(id));

    if (category) {
      // if no more of the category's children are selected, remove the category
      const categoryChildren = (category.children || []).map(c => c.id);
      if (!subjectIds.some(id => categoryChildren.includes(id))) {
        subjectIds = subjectIds.filter(id => id !== category.id);
      }
      // if no more of the category's parents are selected, remove the parent
      const level1 = findParent(category.id, allSubjects);
      if (level1 && level1.parent !== "all") {
        const level1Parent = level1.parent;
        const parentChildren = (level1Parent.children || []).map(c => c.id);
        if (!subjectIds.some(id => parentChildren.includes(id))) {
          subjectIds = subjectIds.filter(id => id !== level1Parent.id);
        }
      }
    }
  } else {
    // add subjects to selectedSubjectIds
    subjectIds = selectedSubjectIds.slice().concat(subjIdsToUpdate);

    const subjPath = findSubjectPath(allSubjects, subj.id);
    if (subjPath) {
      subjPath.forEach(id => {
        if (!subjectIds.includes(id)) {
          subjectIds.unshift(id);
        }
      });
    }
  }

  return subjectIds;
};
