import * as React from "react";

import AnchorIntersectionObserver from "~/components/core/AnchorIntersectionObserver";
import { PERIODS } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";
import { Range } from "~/declarations/filters";
import { segment } from "~/utils/segment";

import { useRequiredAuthContext } from "../auth/common";
import DashboardActiveStudentsChart from "./DashboardActiveStudentsChart";
import DashboardContext from "./DashboardContext";
import DashboardFilteredStatistic from "./DashboardFilteredStatistic";
import DashboardFilterToolbar from "./DashboardFilterToolbar";
import DashboardGuide from "./DashboardGuide";
import DashboardGuideContext from "./DashboardGuide/DashboardGuideContext";
import DashboardHeader from "./DashboardHeader";
import DashboardLiveTutoringTotalCompletedChart from "./DashboardLiveTutoringTotalCompletedChart";
import DashboardLiveVsWritingLabChart from "./DashboardLiveVsWritingLabChart";
import DashboardPowerUsers from "./DashboardPowerUsers/DashboardPowerUsers";
import { useDashboardQueryState } from "./dashboardQueryState";
import {
  DashboardStudentRatingsOfTutors,
  DashboardTutorRatingsOfStudents
} from "./DashboardRatings";
import DashboardStudentQuotes from "./DashboardStudentQuotes/DashboardStudentQuotes";
import DashboardSubjectStats from "./DashboardSubjectStats/DashboardSubjectStats";
import DashboardTotalSessionsChart from "./DashboardTotalSessionsChart";
import DashboardWritingLabPapersCompletedChart from "./DashboardWritingLabPapersCompletedChart";
import { dateFilterLabel } from "./dateFilterLabel";
import useScaleRestrictions from "./useScaleRestrictions";

const Dashboard: React.FC = (): JSX.Element => {
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const filtersRef = React.useRef<HTMLDivElement | null>(null);
  const generalReportsRef = React.useRef<HTMLDivElement | null>(null);

  const papersCompleted = React.useRef<HTMLDivElement | null>(null);
  const liveLessons = React.useRef<HTMLDivElement | null>(null);
  const totalLessons = React.useRef<HTMLDivElement | null>(null);
  const filteredStatisticRef = React.useRef<HTMLDivElement | null>(null);
  const subjectStatsRef = React.useRef<HTMLDivElement | null>(null);
  const sessionsCompleted = React.useRef<HTMLDivElement | null>(null);
  const activeStudents = React.useRef<HTMLDivElement | null>(null);
  const ratingsRef = React.useRef<HTMLDivElement | null>(null);
  const powerUsersRef = React.useRef<HTMLDivElement | null>(null);

  const [
    {
      fromDt: fromDtFilter,
      tillDt: tillDtFilter,
      subjects: subjectFilter,
      moderation: lessonFilter,
      period: periodFilter,
      scale: scaleFilter
    }
  ] = useDashboardQueryState();

  const presetRange = RANGE_GETTERS[periodFilter];
  const rangeFilter: Range = presetRange
    ? presetRange()
    : [fromDtFilter, tillDtFilter];

  const selectedPeriod = PERIODS.find(({ period }) => period === periodFilter);

  if (!selectedPeriod) {
    console.error(`unknown period ${periodFilter}`);
    throw new Error(`unknown period ${periodFilter}`);
  }

  const restrictedScales = useScaleRestrictions(rangeFilter);

  const {
    schoolYearStartDate,
    selectedUmbrella: {
      umbrellaAccName,
      billingMode,
      timeLimitMinutes: limitMinutes,
      expirationDate: dateExpires
    }
  } = useRequiredAuthContext();

  React.useEffect(() => {
    segment.page("Dashboard");
  }, []);

  return (
    <DashboardContext.Provider
      key={umbrellaAccName}
      value={{
        filter: {
          fromDt: rangeFilter[0]?.toISOString(),
          tillDt: rangeFilter[1]?.toISOString(),
          scale: scaleFilter,
          lesson: lessonFilter,
          subjects: subjectFilter ?? undefined
        },
        selectedPeriod,
        umbrellaLimits: {
          billingMode,
          minutes: limitMinutes,
          expires: dateExpires
        },
        explicit: true,
        restrictedScales
      }}
    >
      <DashboardGuideContext.Provider
        value={{
          headerRef,
          filtersRef,
          generalReportsRef,
          subjectStatsRef,
          ratingsRef,
          powerUsersRef
        }}
      >
        <AnchorIntersectionObserver
          refs={[
            { id: "", ref: headerRef, forceOffset: 0 },
            { id: "papersCompleted", ref: papersCompleted },
            { id: "generalReports", ref: generalReportsRef },
            { id: "liveLessons", ref: liveLessons },
            { id: "totalLessons", ref: totalLessons },
            { id: "filteredStatistic", ref: filteredStatisticRef },
            { id: "subjectStats", ref: subjectStatsRef },
            { id: "sessionsCompleted", ref: sessionsCompleted },
            { id: "activeStudents", ref: activeStudents },
            { id: "ratings", ref: ratingsRef },
            { id: "powerUsers", ref: powerUsersRef }
          ]}
        />
        <DashboardGuide />

        <div
          id="header"
          ref={headerRef}
          className="border-b-2 border-cream-300"
        >
          <DashboardHeader />
        </div>

        <div className="h1 mx-0 sm:mx-[30px] mt-[24px] mb-[8px]">
          <span className="font-normal">Usage for </span>
          {dateFilterLabel(
            selectedPeriod.title,
            schoolYearStartDate,
            rangeFilter
          )}
        </div>

        <DashboardFilterToolbar ref={filtersRef} rangeFilter={rangeFilter} />

        <div className="flex flex-col gap-[48px] flex-grow mx-0 sm:mx-[30px] h-full pt-[40px]">
          <div
            className="flex flex-col gap-[24px]"
            id="generalReports"
            ref={generalReportsRef}
          >
            <div id="sessionsCompleted" ref={sessionsCompleted}>
              <DashboardTotalSessionsChart />
            </div>
            <div id="activeStudents" ref={activeStudents}>
              <DashboardActiveStudentsChart />
            </div>
            <div id="totalLessons" ref={totalLessons}>
              <DashboardLiveTutoringTotalCompletedChart />
            </div>
            {!subjectFilter && (
              <>
                <div id="papersCompleted" ref={papersCompleted}>
                  <DashboardWritingLabPapersCompletedChart />
                </div>
                <div id="liveLessons" ref={liveLessons}>
                  <DashboardLiveVsWritingLabChart />
                </div>
              </>
            )}
          </div>

          <div
            id="filteredStatistic"
            ref={filteredStatisticRef}
            className="flex gap-[16px] self-stretch flex-col sm:flex-row w-full items-center sm:items-start"
          >
            <DashboardFilteredStatistic />
          </div>

          <div id="subjectStats" ref={subjectStatsRef}>
            <DashboardSubjectStats />
          </div>

          <div id="ratings" ref={ratingsRef} className="flex gap-[16px] flex-col sm:flex-row">
            <DashboardStudentRatingsOfTutors />
            <DashboardTutorRatingsOfStudents />
          </div>

          <div>
            <DashboardStudentQuotes />
          </div>

          <div id="powerUsers" ref={powerUsersRef}>
            <DashboardPowerUsers />
          </div>
        </div>
      </DashboardGuideContext.Provider>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
