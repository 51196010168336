import { Paper } from "@material-ui/core";
import { AreaChart, DataSet, Scale, TooltipProps } from "@tutorme/area-chart";
import { format, max, min, parseISO } from "date-fns";
import React from "react";

import { DashboardApiQuery } from "~/utils/http";

import { useRequiredAuthContext } from "../auth/common";

type DashboardAreaChartProps = {
  apiQuery: DashboardApiQuery;
  label: string;
  data?: Array<{ dt: string; value: number }>;
};

function apiQueryScaleToChartScale(scale: DashboardApiQuery["scale"]): Scale {
  switch (scale) {
    case "hourly":
      return "hour";
    case "daily":
      return "day";
    case "weekly":
      return "week";
    case "monthly":
      return "month";
  }
}

const colorPairs = [
  {
    areaColor: "#A7E8CF",
    lineColor: "#195E44"
  },
  {
    areaColor: "#8DD7FF",
    lineColor: "#007ABD"
  }
];

function areaChartDataToDatasets(
  labels: string[],
  data?: Array<{ dt: string; value: number; group?: number }>
): DataSet[] {
  if (!data?.length) {
    return [];
  }
  const datasets: DataSet[] = [];
  for (let idx = 0; ; idx++) {
    const items = data.filter(x => (x.group ?? 0) === idx);
    if (!items.length) {
      break;
    }
    datasets.push({
      ...colorPairs[idx],
      data: items.map(x => ({
        date: parseISO(x.dt),
        value: x.value
      })),
      label: labels[idx] ?? "Unknown"
    });
  }
  return datasets;
}

const CustomTooltip: React.FC<TooltipProps> = ({
  top,
  left,
  rows,
  datasetLabels
}: TooltipProps) => {
  const style = React.useMemo(
    () => ({
      top,
      left
    }),
    [top, left]
  );
  if (!rows.length) {
    return null;
  }
  return (
    <Paper
      className="absolute pointer-events-none p-[2px] rounded-[4px]"
      elevation={3}
      style={style}
    >
      <table className="bg-white explanatoryText p-[10px] rounded-[4px]">
        <tbody>
          <tr>
            <td className="pr-[5px]">Date:</td>
            <td>{format(rows[0].date, "MM/dd/yy")}</td>
          </tr>
          {rows.map((row, idx) => (
            <tr key={idx}>
              <td className="pr-[5px]">{datasetLabels[idx]}:</td>
              <td>
                {row.value === Math.round(row.value)
                  ? row.value.toFixed(0)
                  : row.value.toFixed(1)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Paper>
  );
};

export const DashboardAreaChart: React.FC<DashboardAreaChartProps> = ({
  apiQuery,
  data,
  label
}) => {
  const {
    user: { weekStart }
  } = useRequiredAuthContext();
  const datasets = React.useMemo(
    () => areaChartDataToDatasets(label.split(","), data),
    [data, label]
  );
  const fromDt = React.useMemo(
    () =>
      min(
        [parseISO(apiQuery.fromDt)].concat(
          datasets.flatMap(dataset => dataset.data.map(row => row.date))
        )
      ),
    [apiQuery.fromDt, datasets]
  );
  const tillDt = React.useMemo(
    () =>
      max(
        [parseISO(apiQuery.tillDt)].concat(
          datasets.flatMap(dataset => dataset.data.map(row => row.date))
        )
      ),
    [apiQuery.tillDt, datasets]
  );

  return (
    <AreaChart
      className="h-[300px] min-w-0 relative"
      from={fromDt}
      to={tillDt}
      scale={apiQueryScaleToChartScale(apiQuery.scale)}
      datasets={datasets}
      isoWeekday={weekStart === 1}
      fontFamily="TT Commons"
      tooltip={CustomTooltip}
    />
  );
};
