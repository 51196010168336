import CheckIcon from "@material-design-icons/svg/filled/check.svg";
import classNames from "classnames";
import * as React from "react";

import { Button } from "~/components/core/Button";

export const HeaderButtons: React.FC<{
  lessons: boolean;
  toggle: (on: boolean) => void;
}> = ({ lessons, toggle }) => {
  return (
    <div className="flex absolute right-[22px] md:right-[28px] top-[48px] md:top-[28px]">
      <Button
        variant="wrapper"
        className={classNames(
          buttonClass,
          "rounded-r-none",
          lessons ? activeClass : "border-r-0"
        )}
        action={() => toggle(true)}
      >
        {lessons && <CheckIcon className="mr-[8px] scale-[75%]" aria-hidden />}
        Live tutoring
      </Button>
      <Button
        variant="wrapper"
        className={classNames(
          buttonClass,
          "rounded-l-none",
          !lessons ? activeClass : "border-l-0"
        )}
        action={() => toggle(false)}
      >
        {!lessons && <CheckIcon className="mr-[8px] scale-[75%]" aria-hidden />}
        Writing Lab
      </Button>
    </div>
  );
};

const buttonClass = classNames(
  "h-[40px] px-[12px] flex flex-grow-0 items-center rounded-[4px] paragraph",
  "border border-navy-900 bg-white hover:bg-chalkboard-100",
  "focus-visible:bg-chalkboard-200 focus-visible:ring-0 focus-visible:ring-offset-0"
);
const activeClass =
  "border-[2px] border-chalkboard-700 bg-chalkboard-100 buttonLabel";
