import React from "react";

import Legend, { LegendDataModel } from "~/components/core/Legend/Legend";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../createDashboardLazyWidget";

const legendData: LegendDataModel[] = [
  { color: "#195E44", text: "Live tutoring" },
  { color: "#007ABD", text: "Writing lab" }
];

const DashboardLiveVsWritingLabChart = createDashboardLazyWidget({
  displayName: "DashboardLiveVsWritingLabChart",
  contentHeight: 310,
  caption: "Live sessions vs Writing Labs",
  clarification:
    "Time spent on Live tutoring sessions compared to Writing Lab reviews.",
  headerEndAdornment: <Legend legendData={legendData} />,
  testid: struct.adminDash.dashboard.writingLabs.lessonsVsWLChart,
  useRender: (apiQuery, resetFilters) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/comparison_sessions/",
      apiQuery,
      true
    );
    React.useEffect(() => trackEvent("ll_v_wl_load_chart", undefined), []);
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
        resetFilters={resetFilters}
        testid={struct.adminDash.dashboard.writingLabs.lessonsVsWLChart}
      >
        <DashboardAreaChart
          apiQuery={apiQuery}
          data={data}
          label="Live Lessons,Writing Labs"
        />
      </WidgetContentNew>
    );
  }
});

export default DashboardLiveVsWritingLabChart;
