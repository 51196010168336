import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

import { SkeletonError } from "~/components/core/StatisticCard/StatisticCardSkeleton";
import { useAuthenticatedFetch } from "~/utils/http";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";
import { HeaderButtons } from "./HeaderButtons";
import { Ratings } from "./Ratings";

const DashboardStudentRatingsOfTutors = createDashboardLazyWidget({
  displayName: "DashboardStudentRatingsOfTutors",
  contentHeight: 300,
  caption: "Student ratings of tutors",
  className: "flex-[55%] md:flex-[66%] relative",
  captionClassName: "!p-[24px]",
  // testid: struct.adminDash.dashboard.generalUsage.totalSessionsChart,
  useRender: (apiQuery, resetFilters) => {
    const [lessons, setLessons] = React.useState(true);

    const { data, loading, error, refetch } = useAuthenticatedFetch(
      lessons
        ? "/api/v1/new_stats/session_ratings/"
        : "/api/v1/new_stats/writing_lab_ratings/",
      { ...apiQuery, isStudentRatings: true },
      true
    );

    // React.useEffect(() => {
    //   trackEvent("load_total_sessions_chart", undefined);
    // }, []);

    const toggle = (on: boolean) => {
      setLessons(on);
      requestAnimationFrame(() => refetch());
    };

    return (
      <>
        <HeaderButtons lessons={lessons} toggle={toggle} />

        {error || loading ? (
          <Skeleton
            className="h-[calc(100%-32px)] flex items-center justify-center bg-cream-300 mx-[24px] my-[12px] transform-none rounded-[8px]"
            animation={error ? false : "pulse"}
          >
            {error && <SkeletonError isChart />}
          </Skeleton>
        ) : (
          <div className="flex gap-[48px] my-[12px] mx-[24px]">
            <Ratings lessons={lessons} data={data} />
          </div>
        )}
      </>
    );
  }
});

export default DashboardStudentRatingsOfTutors;
